const api = {
  // baseUrl: 'https://bloka3.mediatorium.co',
  baseUrl:
    process.env.NODE_ENV === 'production'
      ? 'https://admin.blok-a3.hr'
      : 'https://admin.blok-a3.hr',
  // baseUrl: window.location.origin,
  paths: {
    homeImages: '/api/homeimages',
    projectDetails: '/api/projects/details',
    projects: '/api/projects',
    contactForm: '/api/contact/form',
    optionLists: '/api/projects/types',
    supportedLanguages: '/api/language/getlanguages',
    translation: '/api/language',
    googleMaps: '/api/googlemaps',
    teamMembers: '/api/employers',
    partners: '/api/associates',
    associates: '/api/associates/partners',
    references: '/api/associates/references',
  },
}

export default api
