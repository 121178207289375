import React from 'react'
import TeamMember from 'components/team/components/team-member'
import { TeamMember as TeamMemberType } from 'api/use-team-members'

interface Props {
  teamMembers: TeamMemberType[]
  size?: number
  memberClassNames?: string
}

const Team: React.FC<Props> = ({ teamMembers, size, memberClassNames }) => {
  return (
    <>
      {teamMembers.map(member => (
        <TeamMember
          key={member.email}
          member={member}
          size={size}
          classNames={memberClassNames}
        />
      ))}
    </>
  )
}

export default Team
