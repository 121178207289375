import { useReducer, useState, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
import { useLocation } from 'react-router'
import _ from 'lodash'
import { RandomImage as string } from './types'

export interface Option {
  id: number
  name: string
  nameEn: string
}
const initialState: ReducerState<Option[]> = {
  isLoading: false,
  data: [],
}
type OptionsReducer = (
  state: ReducerState<Option[]>,
  action: ReducerAction<Option[]>
) => ReducerState<Option[]>

const useOptions = () => {
  const [state, dispatch] = useReducer(reducer as OptionsReducer, initialState)

  const getData = async () => {
    dispatch({ type: 'LOAD' })
    const response = await fetch(`${api.baseUrl}${api.paths.optionLists}`)
    try {
      if (!response.ok) throw new Error(response.status.toString())
      const json = await response.json()
      dispatch({ type: 'SUCCESS', payload: json })
    } catch (err) {
      dispatch({ type: 'ERROR' })
    }
  }

  return [state, getData] as const
}

export interface Filter extends Option {
  isSelected: boolean
}
// type Filters = Record<number, Filter>
const useFilters = () => {
  const [options, getOptions] = useOptions()
  useEffect(() => {
    getOptions()
  }, [])
  const [filters, setFilters] = useState<Record<string, Filter>>({})
  const location = useLocation()
  useEffect(() => {
    if (options.data) {
      // construct filters structure
      const newFilters = options.data.reduce<Record<string, Filter>>(
        (result, currFilter) => ({
          ...result,
          [currFilter.id]: { ...currFilter, isSelected: true },
        }),
        {}
      )
      // extract selected filters from url if specified
      if (location.search.length && options.data.length) {
        const tagsInUrl = location.search
          .substr(6) // remove ?tags=
          .split('&tags=') // extract array of tagnames
          .map(tagName =>
            _.keys(newFilters).find(id => newFilters[id].name === tagName)
          ) // map corresponding tag id-s to the tagnames
          .filter(id => id)

        _.keys(newFilters).forEach(id => {
          newFilters[id].isSelected = tagsInUrl.includes(id)
        })
      }
      setFilters(newFilters)
    }
  }, [options.data])

  const setFilter = (id: number, value: boolean) => {
    if (_.has(filters, id)) {
      setFilters(prev => {
        const newFilters = _.cloneDeep(prev)
        newFilters[id].isSelected = value
        return newFilters
      })
    }
  }

  return [filters, setFilters, setFilter] as const
}

export default useFilters
