import { defineMessages } from 'react-intl'

export default defineMessages({
  // contact info
  // title: {
  //   id: 'contact.title',
  //   defaultMessage: 'Kontakt',
  // },
  email: {
    id: 'contact.email',
    defaultMessage: 'info@blok-a3.hr',
  },
  phone: {
    id: 'contact.phone',
    defaultMessage: '+385 95 65 01 720',
  },
  inquiryRecipients: {
    id: 'contact.inquiryRecipients.title',
    defaultMessage: 'Kontakt osobe',
  },
  // office info
  officeTitle: {
    id: 'contact.office.title',
    defaultMessage: 'Ured',
  },
  hqLabel: {
    id: 'contact.office.hq.label',
    defaultMessage: 'Sjedište',
  },
  hqAddress: {
    id: 'contact.office.hq.address',
    defaultMessage: 'Prilaz Vladislava Brajkovića 10, 10020 Zagreb',
  },
  // bureauLabel: {
  //   id: 'contact.office.bureau.title',
  //   defaultMessage: 'Poslovnica',
  // },
  // bureauAddress: {
  //   id: 'contact.office.bureau.address',
  //   defaultMessage: 'Avenija Dubrovnik 10, Zagreb',
  // },
  // firm info
  firmInfo: {
    id: 'contact.firmInfo.title',
    defaultMessage: 'Tvrtka',
  },
  oibLabel: { id: 'contact.firmInfo.oib.title', defaultMessage: 'OIB' },
  oibValue: { id: 'contact.firmInfo.oib.value', defaultMessage: '46529632639' },
  mbTitle: { id: 'contact.firmInfo.mb.title', defaultMessage: 'MB' },
  mbValue: { id: 'contact.firmInfo.mb.value', defaultMessage: '080876912' },
  pdvIdBrTitle: {
    id: 'contact.firmInfo.pdvIdBr.title',
    defaultMessage: 'PDV id. broj',
  },
  pdvIdBrValue: {
    id: 'contact.firmInfo.pdvIdBr.value',
    defaultMessage: 'HR46529632639',
  },

  // payment info
  bankTitle: { id: 'contact.payment.bank.title', defaultMessage: 'Banka' },
  bankValue: {
    id: 'contact.payment.bank.value',
    defaultMessage: 'Erste&Steiermärkische bank d.d.',
  },
  ibanTitle: { id: 'contact.payment.iban.title', defaultMessage: 'IBAN' },
  ibanValue: {
    id: 'contact.payment.iban.value',
    defaultMessage: 'HR3024020061100677597',
  },
  swiftBicTitle: {
    id: 'contact.payment.swiftBic.title',
    defaultMessage: 'SWIFT/BIC',
  },
  swiftBicValue: {
    id: 'contact.payment.swiftBic.value',
    defaultMessage: 'ESBCHR22',
  },

  // map
  markerMessage: {
    id: 'contact.markerMessage',
    defaultMessage: 'Tu smo!',
  },
  // form
  formTitle: {
    id: 'contact.form.title',
    defaultMessage: 'Upit',
  },
  // contact persuasion
  contactQuestion: {
    id: 'contact.contactQuestion',
    defaultMessage: 'Zanima vas nešto specifično?',
  },
  openForm: {
    id: 'contact.openForm',
    defaultMessage: 'POŠALJI UPIT',
  },
  // after contact
  successConfirmation: {
    id: 'contact.form.success.confirmation',
    defaultMessage: 'Upit uspješno poslan',
  },
  successAffirmation: {
    id: 'contact.form.success.affirmation',
    defaultMessage: 'Javit ćemo vam se u najkraćem mogućem roku',
  },
})
