/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import './hover-select.scss'

interface Props {
  value: string
  options: string[]
  onChangeValue?: (value: string) => void
}

const HoverSelect: React.FC<Props> = ({ value, options, onChangeValue }) => {
  const [currentValue, setCurrentValue] = useState(value)
  useEffect(() => {
    if (onChangeValue) onChangeValue(currentValue)
  }, [currentValue])

  return (
    <div className=" hover-select d-flex flex-row-reverse flex-lg-column-reverse align-items-center">
      <div className="item current px-1 px-lg-3 py-lg-1">{currentValue}</div>
      {options
        .filter(option => option !== currentValue)
        .map(option => (
          <div
            key={option}
            className="item option px-1 px-lg-3 py-lg-1"
            onClick={() => setCurrentValue(option)}
          >
            {option}
          </div>
        ))}
    </div>
  )
}

export default HoverSelect
