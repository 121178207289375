import { defineMessages } from 'react-intl'

export default defineMessages({
  // project info labels
  team: {
    id: 'project.team.title',
    defaultMessage: 'Projektni tim',
  },
  investor: {
    id: 'project.investor.title',
    defaultMessage: 'Investitor',
  },
  location: {
    id: 'project.location.title',
    defaultMessage: 'Lokacija',
  },
  surface: {
    id: 'project.surface.title',
    defaultMessage: 'Površina',
  },
  phase: {
    id: 'project.phase.title',
    defaultMessage: 'Faza',
  },
  funds: {
    id: 'project.funds.title',
    defaultMessage: 'Fondovi',
  },

  relatedProjects: {
    id: 'project.relatedProjects.title',
    defaultMessage: 'Slični projekti',
  },

  // contact persuasion
  contactQuestion: {
    id: 'project.contact.question',
    defaultMessage: 'Zanimaju Vas naši projekti?',
  },
  contactAction: {
    id: 'project.contact.action',
    defaultMessage: 'Kontaktirajte nas!',
  },
})
