import React from 'react'
import { MapObject } from 'api/types'
import { Link } from 'react-router-dom'
import { routes } from 'assets/constants'
import AspectRatioBox from 'components/aspect-ratio-box'
import MarkerWithInfoWindow from '../marker-with-info-window'

const windowWidth = 256

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Building extends MapObject {}

interface Props {
  building: Building
}
const BuildingMapMarker: React.FC<Props> = ({ building }) => {
  return (
    <MarkerWithInfoWindow
      key={building.permalink}
      position={
        building.location.lat && building.location.lng && building.location
      }
    >
      <div className="container w-100 h-100 px-0">
        <Link
          to={`${routes.projects}/details/${building.permalink}`}
          className="h-100"
          onClick={e => {
            if (!building.permalink) e.preventDefault()
          }}
        >
          {building.imgSrc && (
            <AspectRatioBox>
              <img
                src={`${building.imgSrc}?width=${windowWidth}&height=144&mode=crop`}
                // src={ph}
                alt={building.title}
                title={building.title}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover', objectPosition: 'center' }}
              />
            </AspectRatioBox>
          )}
          <div className="col py-2 px-2" style={{ width: windowWidth }}>
            {/* {building.imgSrc && (
            <img
            src={`${building.imgSrc}?width=256&height=144&mode=crop`}
            alt={building.title}
            title={building.title}
            />
          )} */}

            {/* <div className="col pt-2 px-2" style={{ minWidth: 140 }}> */}
            <h6 style={building.imgSrc ? {} : { paddingRight: '2rem' }}>
              {building.title}
            </h6>
            <p>{building.year}</p>
          </div>
          {/* </div> */}
        </Link>
      </div>
    </MarkerWithInfoWindow>
  )
}

export default BuildingMapMarker
