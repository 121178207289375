import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import messages from './use-contact-data.messages'

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)

const buildErrorArray = (
  errorCases: { ifCondition: boolean; addMessage: string }[]
) => {
  return errorCases.reduce(
    (result, item) =>
      item.ifCondition ? [...result, item.addMessage] : result,
    [] as string[]
  )
}

const useContactData = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [image, setImage] = useState('')
  const [errors, setErrors] = useState({
    name: [] as string[],
    phone: [] as string[],
    email: [] as string[],
    message: [] as string[],
  })

  const { formatMessage: f } = useIntl()
  // validate name
  useEffect(() => {
    setErrors(prev => {
      const newErrors = !name.trim().length
        ? [f(messages.required)]
        : buildErrorArray([
            {
              ifCondition: !name.trim().length,
              addMessage: f(messages.required),
            },
            {
              ifCondition: name.trim().split(/\s+/).length < 2,
              addMessage: f(messages.nameAndSurname),
            },
          ])
      return {
        ...prev,
        name: newErrors,
      }
    })
  }, [name])

  // validate email
  useEffect(() => {
    setErrors(prev => {
      const newErrors = !email.trim().length
        ? [f(messages.required)]
        : buildErrorArray([
            {
              ifCondition: !email.trim().length,
              addMessage: f(messages.required),
            },
            {
              ifCondition: !validEmailRegex.test(email),
              addMessage: f(messages.email),
            },
          ])
      return {
        ...prev,
        email: newErrors,
      }
    })
  }, [email])

  // validate message
  useEffect(() => {
    setErrors(prev => {
      const newErrors = !message.trim().length
        ? [f(messages.required)]
        : buildErrorArray([])
      return {
        ...prev,
        message: newErrors,
      }
    })
  }, [message])

  return {
    name: {
      value: name,
      set: setName,
      errors: errors.name,
    },
    email: {
      value: email,
      set: setEmail,
      errors: errors.email,
    },
    phone: {
      value: phone,
      set: setPhone,
      errors: errors.phone,
    },
    message: {
      value: message,
      set: setMessage,
      errors: errors.message,
    },
    image: {
      value: image,
      set: setImage,
    },
    isValid: !Object.values(errors).some(val => val.length > 0),
    setErrors,
  }
}
export default useContactData
