import React, { useContext } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPaperclip, faTimes } from '@fortawesome/pro-light-svg-icons'
import { DataContext } from 'components/data-container/data-container'
import { useIntl } from 'react-intl'
import messages from './attachment-notification.messages'
import { FaPaperclip, FaTimes } from 'assets'

const AttachmentNotification = () => {
  const { contactData } = useContext(DataContext)
  const { formatMessage: f } = useIntl()
  if (!contactData.image.value) return null
  return (
    <div className="d-flex align-items-center email-attachment">
      {/* <FontAwesomeIcon icon={faPaperclip} color="#1B75BC" /> */}
      <FaPaperclip />
      <span className="mx-2" style={{ fontSize: 12 }}>
        {f(messages.imageAttachment)}
      </span>
      <span
        className="ml-auto text-primary"
        onClick={() => contactData.image.set('')}
        style={{ cursor: 'pointer' }}
      >
        <FaTimes size={14} color="#14588d" />
      </span>
      {/* <FontAwesomeIcon
        className="ml-auto text-primary"
        icon={faTimes}
        size="sm"
        onClick={() => contactData.image.set('')}
        style={{ cursor: 'pointer' }}
      /> */}
    </div>
  )
}

export default AttachmentNotification
