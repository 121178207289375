import React from 'react'
import { NavLink } from 'react-router-dom'
import FiltersDropdown from 'components/filters-dropdown'
import CopyToClipboard from 'components/copy-to-clipboard'
import { routes } from 'assets/constants'
import { useIntl } from 'react-intl'
import messages from './site-navigation-addon.messages'

function SiteNavigationAddOn() {
  const { formatMessage: f } = useIntl()
  return (
    <>
      <div className="row mx-0 w-100 h-100 ">
        <div className="row h-100 align-items-center mx-0 w-100">
          <div className="navigation-addon col-12 d-flex align-items-center">
            <div className="tabs-navigation">
              <NavLink
                to={`${routes.projects}${routes.gallery}`}
                data-tabs="tab-1"
                activeClassName="active-tab"
              >
                {f(messages.galleryTitle)}
              </NavLink>
              <NavLink
                to={`${routes.projects}${routes.map}`}
                data-tabs="tab-2"
                activeClassName="active-tab"
              >
                {f(messages.mapTitle)}
              </NavLink>
            </div>

            <FiltersDropdown />
            <CopyToClipboard />
          </div>
        </div>
      </div>
    </>
  )
}

export default SiteNavigationAddOn
