import React, { useState, useContext } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faCopy as faCopySolid,
//   faLink as faLinkSolid,
// } from '@fortawesome/free-solid-svg-icons'
import { Toast } from 'react-bootstrap'
import { DataContext } from 'components/data-container/data-container'
import { useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import messages from './copy-to-clipboard.messages'
import './copy-to-clipboard.scss'
import { FaLink } from 'assets'

const CopyToClipboard = () => {
  const [showToast, setShowToast] = useState(false)
  const { filters } = useContext(DataContext)
  const location = useLocation()

  // write url with filter-params to clipboard
  const handleShare = () => {
    const selectedFilters = _.values(filters.data)
      .filter(filter => filter.isSelected)
      .map(filter => filter.name)
    const searchParams = `?tags=${selectedFilters.join('&tags=')}`

    navigator.clipboard.writeText(
      `${window.location.host}${location.pathname}${searchParams}`
    )
    setShowToast(true)
  }

  const { formatMessage: f } = useIntl()

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-dark ml-3"
        onClick={handleShare}
        title={f(messages.title)}
      >
        {/* <FontAwesomeIcon
          icon={faLinkSolid}
          mask={faCopySolid}
          transform="shrink-10 right-2"
          size="lg"
        /> */}
        <FaLink />
      </button>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        style={
          showToast
            ? { transform: 'translateY(0)' }
            : { transform: 'translateY(100%)' }
        }
        delay={1500}
        autohide
      >
        <Toast.Header
          className="col-12 d-flex align-items-center justify-content-between"
          color="#fff"
        >
          {f(messages.toast)}
        </Toast.Header>
      </Toast>
    </>
  )
}

export default CopyToClipboard
