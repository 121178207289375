import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from 'components'
import { IntlProviderWrapper } from 'i18n/components/intl-provider-wrapper'
import * as serviceWorker from './serviceWorker'
import './App.scss';

ReactDOM.render(
  <Router>
    <IntlProviderWrapper>
      <App />
    </IntlProviderWrapper>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
