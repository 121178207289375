/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { routes } from 'assets/constants'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { MenuContext } from 'components/navigation/MainNavigation'
import logo from 'assets/images/mobile-logo.svg'
import { FaTimes } from 'assets'

function LogoHmtl() {
  const match = useRouteMatch({ path: routes.home, exact: true })
  const { setShowMobNav } = useContext(MenuContext)
  return (
    <div className="d-flex flex-column aside-left border-bottom border-bottom-lg-0">
      <Link
        to="/"
        className="border-right border-bottom logo-html bg-diagonale w-100 d-none d-lg-block"
      >
        <div className="row h-50 border-bottom mx-0">
          <div className="col-12 blok txt">blok</div>
        </div>
        <div className="row h-50">
          <div className="col-6 border-right pr-0">
            <div className="row border-bottom mx-0 h-50">
              <div className="col-12 a3 txt">A3</div>
            </div>
            <div className="row h-50 mx-0">
              <div className="col-6 px-0 border-right h-100">
                <div className="row mx-0 border-bottom h-50"></div>
                <div className="row mx-0 h-50">
                  <div className="col-6 px-0 border-right">
                    <div className="row mx-0 border-bottom h-50"></div>
                    <div className="row"></div>
                  </div>
                  <div className="col-6 px-0"></div>
                </div>
              </div>
              <div className="col-6 px-0"></div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      </Link>
      <div className="navigation-top-sm d-lg-none d-flex flex-row align-items-center justify-content-between">
        <Link
          to="/"
          className="d-lg-none d-flex flex-row align-items-center text-decoration-none"
          onClick={() => setShowMobNav(false)}
        >
          {/* <Logo /> */}
          <img src={logo} alt="" width="20px" />
          <div className="logo-sm-text">
            blok <b>A3</b>
          </div>
        </Link>
        <div
          role="button"
          className="menu-sm-triger p-2"
          onClick={() => setShowMobNav(prev => !prev)}
        >
          <span>Menu</span>

          {/* <FontAwesomeIcon icon={faTimes} /> */}
          <FaTimes size={16} color="#000" />
        </div>
      </div>

      <div
        className={`to-be-grown d-none d-lg-flex flex-grow-1 ${
          match ? '' : 'bg-diagonale border-right'
        }`}
        style={match ? { cursor: 'none' } : {}}
      />
    </div>
  )
}

export default LogoHmtl
