import React, { useRef, useEffect } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import { compose, withProps } from 'recompose'
import { api } from 'assets/constants'
// import PolylineWithInfoWindow from './components/polyline-with-info-window'
import './style.scss'
import RoadMapMarker from './components/road-map-marker'
import BuildingMapMarker from './components/building-map-marker'

const GoogleMapWithMarkers = compose(
  withProps({
    googleMapURL: `${api.baseUrl}${api.paths.googleMaps}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, flex: 1 }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ buildings = [], roads = [], draggable = true }) => {
  const map = useRef(null)

  const getMapBounds = () => {
    const bounds = new window.google.maps.LatLngBounds(
      buildings[0]?.location || roads[0]?.location
    )

    buildings.concat(roads).forEach(object => {
      bounds.extend(
        new window.google.maps.LatLng(object.location.lat, object.location.lng)
      )
    })
    return bounds
  }

  // fit map bounds to contain map objects
  useEffect(() => {
    if (map && window.google.maps && buildings.length + roads.length) {
      map.current.fitBounds(getMapBounds())
    }
  }, [buildings, roads])

  return (
    <GoogleMap
      ref={map}
      defaultZoom={8}
      options={{
        maxZoom: 16,
        draggable,
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
      defaultCenter={{ lat: 46, lng: 16 }}
    >
      {buildings.map(building => (
        <BuildingMapMarker key={building.permalink} building={building} />
      ))}
      {roads.map(road => (
        <RoadMapMarker key={road.title} road={road} />
      ))}

      {/* {roads.map(road => (
        <PolylineWithInfoWindow key={road.title} road={road} />
      ))} */}
    </GoogleMap>
  )
})

export default GoogleMapWithMarkers
