import { useReducer, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
import { RandomImage } from './types'

const initialState: ReducerState<RandomImage[]> = {
  isLoading: false,
  data: [],
}
type HomeImageReducer = (
  state: ReducerState<RandomImage[]>,
  action: ReducerAction<RandomImage[]>
) => ReducerState<RandomImage[]>

const useHomeImages = () => {
  const [state, dispatch] = useReducer(
    reducer as HomeImageReducer,
    initialState
  )

  const getData = async () => {
    dispatch({ type: 'LOAD' })
    const response = await fetch(`${api.baseUrl}${api.paths.homeImages}`)
    try {
      if (!response.ok) throw new Error(response.status.toString())
      const json = await response.json()
      dispatch({ type: 'SUCCESS', payload: json })
    } catch (err) {
      dispatch({ type: 'ERROR' })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return [state, getData] as const
}

export default useHomeImages
