import React, { useContext } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFacebookSquare,
//   faLinkedin,
//   faInstagramSquare,
// } from '@fortawesome/free-brands-svg-icons'
import './filters-dropdown.scss'
import HoverSelect from 'components/hover-select'
import { IntlContext } from 'i18n/components/intl-provider-wrapper'
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'assets'

const socialButtonsLocal = [
  {
    id: 1,
    text: 'FB',
    url: 'https://www.facebook.com/blokA3arhitektura',
    //icon: FaFacebookSquare,
  },
  {
    id: 2,
    text: 'LinkedIn',
    // url: '/linkedin',
    //icon: FaLinkedin,
  },
  {
    id: 3,
    text: 'INSTA',
    url: 'https://www.instagram.com/blok_a3/',
    //icon: FaInstagramSquare,
  },
]

interface Props {
  classes?: {
    root?: string
    socialList?: string
    localeSelect?: string
    socialButton?: string
  }
}

const SocialButtons: React.FC<Props> = ({ classes }) => {
  const socialLinks = socialButtonsLocal
    .filter(b => b.url)
    .map(navItem => (
      <li className="list-unstyled" key={navItem.id}>
        <a
          href={navItem.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`d-inline-block ${classes?.socialButton}`}
        >
          {/* <FontAwesomeIcon icon={navItem.icon} size="lg" /> */}
          {navItem.text === 'FB' ? <FaFacebookSquare /> : <FaInstagramSquare />}
        </a>
      </li>
    ))

  const { locale, supportedLocales, switchLocale } = useContext(IntlContext)

  return (
    // <div className="border-left-md border-top border-top-md-0 bg-diagonale aside-right d-flex justify-content-between flex-column px-3">
    <div
      className={`bg-diagonale d-flex justify-content-between px-3 ${classes?.root}`}
    >
      <ul
        className={`social-list d-flex m-0 justify-content-center align-items-center text-center ${classes?.socialList}`}
      >
        {socialLinks}
      </ul>

      <div
        className={`d-flex flex-column align-items-center justify-content-center ${classes?.localeSelect}`}
      >
        <HoverSelect
          value={locale}
          options={supportedLocales}
          onChangeValue={switchLocale}
        />
      </div>
    </div>
  )
}

export default SocialButtons
