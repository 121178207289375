import React, { createContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useLocalStorage } from '@rehooks/local-storage'
import Cookies from 'js-cookie'
import { Messages, fetchMessages, fetchSupportedLanguages } from 'api/i18n'
import { i18n } from 'assets/constants'

type ContextType = {
  locale: string
  switchLocale: (locale: string) => void
  supportedLocales: string[]
}
const { defaultLocale, localeCookieName } = i18n
export const IntlWrapperContext = createContext<ContextType>({
  locale: Cookies.get(localeCookieName) || defaultLocale,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  switchLocale: (newLocale: string) => {},
  supportedLocales: [defaultLocale],
})

interface Props {
  children: React.ReactNode
}
export const IntlProviderWrapper: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState(
    Cookies.get(localeCookieName) || defaultLocale
  )
  // get locale from cookie on mount
  useEffect(() => {
    const localeCookie = Cookies.get(localeCookieName)
    if (!localeCookie) {
      Cookies.set(localeCookieName, locale)
    } else if (localeCookie !== locale) {
      setLocale(localeCookie)
    }
  }, [])

  // update cookie on locale change
  useEffect(() => {
    Cookies.set(localeCookieName, locale)
  }, [locale])

  const [supportedLocales, setSupportedLocales] = useState([defaultLocale])

  // get supportedLocales on mount
  useEffect(() => {
    async function updateSupportedLocales() {
      const locales = await fetchSupportedLanguages()
      setSupportedLocales(locales)
    }
    updateSupportedLocales()
  }, [])

  const [translationsInLS, setTranslationsInLS] = useLocalStorage<
    Record<string, Messages>
  >('translations', {})
  const [messages, setMessages] = useState({})

  // get and use new translations on locale change
  useEffect(() => {
    async function updateTranslations() {
      const newMessages = await fetchMessages(locale)
      const newTranslations = translationsInLS || {}
      newTranslations[locale] = newMessages
      setTranslationsInLS(newTranslations)
      setMessages(newMessages)
    }
    updateTranslations()
  }, [locale])

  return (
    <IntlWrapperContext.Provider
      value={{
        locale,
        switchLocale: setLocale,
        supportedLocales,
      }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </IntlWrapperContext.Provider>
  )
}
