import { useReducer, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'

export interface Associate {
  image: string
  link?: string
  name: string
}
export const initialState: ReducerState<Associate[]> = {
  isLoading: false,
  data: [],
}
type AssociatesReducer = (
  state: ReducerState<Associate[]>,
  action: ReducerAction<Associate[]>
) => ReducerState<Associate[]>

const useAssociates = () => {
  const [state, dispatch] = useReducer(
    reducer as AssociatesReducer,
    initialState
  )

  const getData = async () => {
    dispatch({ type: 'LOAD' })
    const response = await fetch(`${api.baseUrl}${api.paths.associates}`)
    try {
      if (!response.ok) throw new Error(response.status.toString())
      const json = await response.json()
      dispatch({ type: 'SUCCESS', payload: json })
    } catch (err) {
      dispatch({ type: 'ERROR' })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return [state, getData] as const
}

export default useAssociates
