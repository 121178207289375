import React, { useRef, useContext, useEffect } from 'react'
import 'tui-image-editor/dist/tui-image-editor.css'
// / <reference path="./react-image-editor.d.ts" />
import ImageEditor from '@toast-ui/react-image-editor'
import { DataContext } from 'components/data-container/data-container'
import { useHistory } from 'react-router'
import { routes } from 'assets/constants'
import SubmitImgBtn from 'components/submit-img-btn'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEngineWarning } from '@fortawesome/pro-light-svg-icons'
import { useIntl } from 'react-intl'
import defaultImage from 'assets/images/1600x900-white-solid-color-background.jpg'
import myTheme from './editor.theme'
import messages from './editor.messages'
import { FaEngineWarning } from 'assets'

const Editor = () => {
  const tuiEditor = useRef<{
    getInstance: () => {
      toDataURL: (options?: any) => string
      on: (e: string, callback: () => void) => void
    }
  }>(null)
  const { contactData, setHandleImgSubmit } = useContext(DataContext)
  const history = useHistory()
  const { formatMessage: f } = useIntl()

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ minHeight: 900 }}>
      <div className="addon-sm bg-diagonale border-bottom p-2">
        <SubmitImgBtn />
      </div>
      <div className="editor-hidden d-xl-none">
        {/* <FontAwesomeIcon className="mb-5" size="2x" icon={faEngineWarning} /> */}
        <FaEngineWarning />
        {f(messages.editorDisabled)}
      </div>
      <ImageEditor
        ref={tuiEditor}
        includeUI={{
          loadImage: {
            path: contactData.image.value
              ? contactData.image.value
              : defaultImage,
            name: 'MyImage',
          },
          cssMaxWidth: '100%',
          cssMaxHeight: '100%',
          theme: myTheme,
          menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'text'],
          initMenu: 'draw',
          menuBarPosition: 'bottom',
        }}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={false}
        onUndoStackChanged={() => {
          if (tuiEditor && tuiEditor.current) {
            const instance = tuiEditor.current.getInstance()
            setHandleImgSubmit(() => {
              return () => {
                const imageData = instance.toDataURL()
                contactData.image.set(imageData)

                history.push(`${routes.contact}/compose-message`)
              }
            })
          }
        }}
      />
    </div>
  )
}

export default Editor
