export default {
  home: '/',
  about: '/about',
  contact: '/contact',
  contactOurTeam: '/contact/our-team',
  contactComposeMessage: '/contact/compose-message',
  projects: '/portfolio',
  map: '/map',
  gallery: '/gallery',
  gdpr: '/gdpr',
  editor: '/editor',
}
