import React, { useState, createContext } from 'react'
import SocialButtons from '../navigation-modules/SocialButtons'
import CentralContent from '../navigation-modules/central-content/CentralContent'
import LogoHtml from '../navigation-modules/LogoHtml'

interface MenucontextInterface {
  setShowMobNav: React.Dispatch<React.SetStateAction<boolean>>
}
export const MenuContext = createContext<MenucontextInterface>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowMobNav: () => {},
})

interface Props {
  topChildren: React.ReactNode
}
const MainNavigation: React.FC<Props> = ({ children, topChildren }) => {
  const [showMobNav, setShowMobNav] = useState(false)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className={`row nav-sm-detector ${showMobNav && 'nav-shown'}`}>
            <MenuContext.Provider value={{ setShowMobNav }}>
              <LogoHtml />
              <CentralContent topChildren={topChildren}>
                {children}
              </CentralContent>
              <SocialButtons classes={{root: 'aside-right px-4 px-lg-0 py-lg-4 flex-lg-column border-left-lg border-top border-top-lg-0 ', socialList:'flex-lg-column ', socialButton: 'mr-3 mr-lg-0 mb-lg-3'}}/>
            </MenuContext.Provider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNavigation
