import React from 'react'
import './aspect-ratio-box.scss'

interface Props {
  ratio?: number
  className?: string
  style?: React.CSSProperties
}
const AspectRatioBox: React.FC<Props> = ({
  children,
  style,
  ratio = 9 / 16,
  className = '',
}) => {
  return (
    <div
      className={`${className} aspect-ratio-box`}
      style={{ ...style, paddingTop: `${ratio * 100}%` }}
    >
      <div className="aspect-ratio-box-inside">{children}</div>
    </div>
  )
}

export default AspectRatioBox
