import React, { useEffect, useRef, useState, useContext } from 'react'
import { DataContext } from 'components/data-container/data-container'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFilter } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import messages from './filters-dropdown.messages'
import { FaFilter } from 'assets'

const FiltersDropdown = () => {
  const { formatMessage: f } = useIntl()
  const { filters, projectsData } = useContext(DataContext)
  const [show, setShow] = useState(false)

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    filters.setSingle(id, e.target.checked)
  }

  // close window on click outside
  const filterWindowToggle = useRef<HTMLDivElement>(null)
  const filterButton = useRef<HTMLButtonElement>(null)
  const handleClickOutside = (e: Event) => {
    if (
      filterWindowToggle &&
      filterWindowToggle.current &&
      filterButton &&
      filterButton.current &&
      (filterWindowToggle.current.contains(e.target as Node | null) ||
        filterButton.current.contains(e.target as Node | null))
    )
      return
    setShow(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [])

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-dark ml-auto position-relative"
        onClick={() => setShow(prev => !prev)}
        ref={filterButton}
        title={f(messages.filtersTitle)}
      >
        {/* <FontAwesomeIcon icon={faFilter} /> */}
        <FaFilter />
      </button>

      {show && (
        <div className="filter-modal mr-6" ref={filterWindowToggle}>
          <div className="body pb-2">
            <div className="title d-flex align-items-center justify-content-between">
              <span>{f(messages.filtersTitle)}</span>
              <span
                className={`spinner-border spinner-border-sm ${!projectsData?.isLoading &&
                  'invisible'}`}
              />
            </div>

            {_.values(filters.data).map(filter => (
              <div
                key={`filter-option-${filter.id}`}
                className="custom-control custom-checkbox pl-3 py-2"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`tag-${filter.id}`}
                  checked={filter.isSelected}
                  onChange={e => handleCheckboxChange(e, filter.id)}
                />
                <label
                  className="custom-control-label d-flex mr-7"
                  htmlFor={`tag-${filter.id}`}
                >
                  {filter.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default FiltersDropdown
