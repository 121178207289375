/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { APIImage } from 'api/api-types'
import AspectRatioBox from 'components/aspect-ratio-box'
import Lightbox from 'components/lightbox'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExpand } from '@fortawesome/free-solid-svg-icons'
import './project-details-slider.scss'
import { FaExpand } from 'assets'

interface Props {
  variableWidth: boolean
  images: APIImage[] | undefined
  autoplaySpeed?: number
}
type LazyLoadSetting = 'ondemand' | 'progressive' | undefined
const ProjectDetailsSlider: React.FC<Props> = ({
  images,
  variableWidth,
  autoplaySpeed = 3000,
}) => {
  const settings = {
    lazyLoad: 'ondemand' as LazyLoadSetting,
    dots: true,
    variableWidth,
    autoplay: true,
    speed: 700,
    autoplaySpeed,
    arrows: false,
    // fade: true,
  }

  const slider = useRef<Slider>(null)

  return (
    <Slider ref={slider} {...settings}>
      {images &&
        images.map((image, index) => (
          <AspectRatioBox key={`slider-img-${image.imageName}`}>
            <img
              src={`${image.imageName}?width=952&height=536&mode=crop`}
              alt=""
              onClick={() => slider?.current?.slickNext()}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
            <Lightbox
              images={images.map(img => ({ src: img.imageName }))}
              index={index}
              className="slider-expand-btn"
            >
              {/* <FontAwesomeIcon icon={faExpand} color="white" /> */}
              <FaExpand />
            </Lightbox>
          </AspectRatioBox>
        ))}
    </Slider>
  )
}

export default ProjectDetailsSlider
