import React from 'react'
import { APIProject } from 'api/api-types'
import './project-gallery.scss'
import AspectRatioBox from 'components/aspect-ratio-box'
import { Link } from 'react-router-dom'
import { routes } from 'assets/constants'
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import avatar from 'assets/images/avatar.png'

interface Props {
  projects: APIProject[]
  scrollPosition: { x: number; y: number }
}

const ProjectGallery = ({ projects, scrollPosition }: Props) => {
  return (
    <>
      {projects.map(project => (
        <Link
          to={`${routes.projects}/details/${project.permaLink}`}
          key={project.permaLink}
          className="col-lg-4 col-xl-3 col-6 mx-0 px-0 gallery-item"
        >
          <AspectRatioBox>
            <LazyLoadImage
              src={`${project.url}?width=450` || avatar}
              srcSet={`${project.url}?width=200 200w ,${project.url}?width=450 300w, ${project.url}?width=400 400w`}
              sizes="(max-width: 750px) 50vw, (max-width: 975px) 25vw, 400px"
              alt={project.name}
              className="gallery-img "
              // placeholderSrc={`${project.url}?width=5`}
              placeholder={
                <div
                  className="w-100 h-100"
                  style={{ backgroundColor: '#76ACD7' }}
                />
              }
              effect="opacity"
              wrapperClassName="h-100 w-100"
              scrollPosition={scrollPosition}
            />
            <div className="item-overlay ">{project.name}</div>
          </AspectRatioBox>
        </Link>
      ))}
    </>
  )
}

export default trackWindowScroll(ProjectGallery)
