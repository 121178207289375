import React, { useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faUsers,
//   faCommentAlt,
//   faLocationArrow,
//   faCopy,
// } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
// import { DataContext } from 'components/data-container/data-container'
import QuarteredImages from 'views/home/components/quartered-images'
import './home.scss'
import { routes, bureauLocation } from 'assets/constants'
import { useIntl } from 'react-intl'
import SocialButtons from 'components/navigation-modules/SocialButtons'
import useHomeImages from 'api/use-home-images'
import funds from 'assets/constants/funds'
import messages from 'components/navigation/main-navigation.messages'
import { FaCommentAlt, FaCopy, FaLocationArrow, FaUsers } from 'assets'

const Home: React.FC = () => {
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
  }
  const { formatMessage: f } = useIntl()
  const [homeImages, getHomeImages] = useHomeImages()
  useEffect(() => {
    getHomeImages()
  }, [])
  return (
    <div
      className="screen-size-detector container-fluid px-0 d-flex"
      style={{ cursor: 'none' }}
    >
      <QuarteredImages images={homeImages} />

      <div className="d-none d-md-flex align-items-center visibility-signs landing">
        {funds.map(fund => (
          <a
            key={fund.img}
            href={fund.link}
            target="_blank"
            rel="noopener noreferrer"
            className="p-0 d-flex align-items-center w-25"
            style={{ maxHeight: '80%' }}
          >
            <img src={fund.img} alt="fund" className="mw-100" />
          </a>
        ))}
      </div>

      <div
        className="landing-mobile d-md-none bg-diagonale"
        style={{ width: '100vw' }}
      >
        <div className="row">
          <div className="slider col-12">
            {/* <img src="/images/img-sm.png" alt="" /> */}
            <Slider {...settings}>
              {homeImages &&
                homeImages.data?.map(image => (
                  <img
                    // src={image.url.replace(/uploads\//, 'uploads/sm/sm-')}
                    src={`${image.url}?width=767&height=431&mode=crop`}
                    alt={image.url}
                    key={image.url}
                  />
                ))}
            </Slider>
          </div>
        </div>

        <div className="border-bottom d-md-none row mx-0">
          {funds.slice(0, 4).map(fund => (
            <a
              key={fund.img}
              href={fund.link}
              target="_blank"
              rel="noopener noreferrer"
              className="col-6 item d-flex align-items-center"
              // style={{ maxWidth: 200 }}
            >
              <img src={fund.img} alt="fund" className="mw-100" />
            </a>
          ))}
        </div>
        <div className="row cards flex-grow-1">
          <Link
            to={routes.about}
            className="item col-6 border-right border-bottom"
          >
            {/* <FontAwesomeIcon icon={faUsers} /> */}
            <FaUsers />
            <span>{f(messages.about)}</span>
          </Link>
          <Link to={routes.contact} className="item col-6 border-bottom">
            {/* <FontAwesomeIcon icon={faCommentAlt} /> */}
            <FaCommentAlt />
            <span>{f(messages.contact)}</span>
          </Link>
          <a
            href={`http://maps.google.com/maps?q=loc:${bureauLocation.lat},${bureauLocation.lng}&navigate=yes`}
            className="item col-6 border-right text-decoration-none"
          >
            {/* <FontAwesomeIcon icon={faLocationArrow} /> */}
            <FaLocationArrow />
            <span>{f(messages.directions)}</span>
          </a>
          <Link to={routes.projects} className="item col-6">
            {/* <FontAwesomeIcon icon={faCopy} /> */}
            <FaCopy size={21} />
            <span>{f(messages.portfolio)}</span>
          </Link>
        </div>
        <SocialButtons
          classes={{
            root: 'home-footer px-4 border-top',
            socialButton: 'mr-3',
          }}
        />
      </div>
    </div>
  )
}

export default Home
