import { useState, useEffect } from 'react'
import api from 'api/paths'

const useFetch = <T>({
  method,
  // body,
  path,
}: // params,
{
  method: string
  path: string
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  // params?: { [key: string]: any }
  // body?: any
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState(false)

  // useEffect(() => {
  async function getData(
    {
      params,
      body,
    }: {
      params?: { [key: string]: any }
      body?: any
    },
    callback?: (success: boolean) => void
  ) {
    setIsFetching(true)

    let url = `${api.baseUrl}${path}`
    if (params) {
      url += '?'

      Object.keys(params).forEach(key => {
        url += `${key}=`
        if (typeof params[key] === 'object') {
          const val = params[key].join(`&${key}=`)
          url += val
          // console.log(params[key].join(','))
          // console.log([1, 2].join())
        } else {
          url += params[key]
        }
      })
    }

    try {
      const formData = new FormData()
      if (body) {
        Object.keys(body).forEach(key => formData.append(key, body[key]))
      }
      const response = await fetch(url.toString(), {
        method,
        body: body ? formData : undefined,
      })
      if (!response.ok) throw new Error(response.status.toString())
      if (method !== 'post') {
        const json: T = await response.json()
        setData(json)
      }
      setIsFetching(false)
      if (callback) callback(true)
    } catch (e) {
      setError(true)
      console.error(e)

      setIsFetching(false)
      if (callback) callback(false)
    }
  }

  //   getData()
  // }, [])

  return { getData, data, isLoading: isFetching, isError: error }
}

export default useFetch
