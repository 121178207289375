import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from 'assets/constants'
import { MenuContext } from './MainNavigation'
import messages from './main-navigation.messages'
import { useIntl } from 'react-intl'

function NavigationItems() {
  const { setShowMobNav } = useContext(MenuContext)

  const { formatMessage: f } = useIntl()
  return (
    <ul className="col-12 navigation-list d-flex flex-column flex-lg-row mb-0 align-items-center flex-wrap">
      <li className="list-unstyled">
        <NavLink
          to={routes.about}
          onClick={() => setShowMobNav(false)}
          activeClassName="active"
        >
          {f(messages.about)}
        </NavLink>
      </li>
      <li className="list-unstyled">
        <NavLink
          to={routes.projects}
          onClick={() => setShowMobNav(false)}
          activeClassName="active"
        >
          {f(messages.portfolio)}
        </NavLink>
      </li>
      <li className="list-unstyled">
        <NavLink
          to={routes.contact}
          onClick={() => setShowMobNav(false)}
          activeClassName="active"
        >
          {f(messages.contact)}
        </NavLink>
      </li>
      <li className="list-unstyled only-desktop">
        <NavLink
          to={routes.editor}
          onClick={() => setShowMobNav(false)}
          activeClassName="active"
        >
          {f(messages.editor)}
        </NavLink>
      </li>
    </ul>
  )
}

export default NavigationItems
