import { defineMessages } from 'react-intl'

export default defineMessages({
  homeTitle: {
    id: 'home.title',
    defaultMessage: 'Početna',
  },
  aboutTitle: {
    id: 'about.title',
    defaultMessage: 'O Nama',
  },
  contactTitle: {
    id: 'contact.title',
    defaultMessage: 'Kontakt',
  },
  projectsTitle: {
    id: 'projects.title',
    defaultMessage: 'Portfolio',
  },
})
