import { useEffect, useState } from 'react'

interface Position {
  x: number | undefined
  y: number | undefined
}
export default () => {
  const [position, setPosition] = useState<Position>({
    x: undefined,
    y: undefined,
  })
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  useEffect(() => {
    const setFromEvent = (e: MouseEvent) => {
      setPosition({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener('mousemove', setFromEvent)
    return () => {
      window.removeEventListener('mousemove', setFromEvent)
    }
  }, [])

  useEffect(() => {
    const setFromEvent = () =>
      setDimensions({ width: window.innerWidth, height: window.innerHeight })
    setDimensions({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener('resize', setFromEvent)
    return () => {
      window.removeEventListener('resize', setFromEvent)
    }
  }, [])

  return { position, dimensions }
}
