import { useReducer, useState, useEffect } from 'react'
import api from 'api/paths'
import reducer, { ReducerState, ReducerAction } from 'api/load-reducer'
import { APIProject } from 'api/api-types'
import axios, { CancelTokenSource } from 'axios'

const initialState: ReducerState<APIProject[]> = {
  isLoading: false,
  data: [],
}
type FilteredProjectsReducer = (
  state: ReducerState<APIProject[]>,
  action: ReducerAction<APIProject[]>
) => ReducerState<APIProject[]>

const constructUrl = (filters: string[]) => {
  let newUrl = `${api.baseUrl}${api.paths.projects}`
  if (filters.length) {
    newUrl += `?tags=`
    newUrl += filters.join(`&tags=`)
  }
  return newUrl
}

const useFilteredProjects = () => {
  // archive of past queries
  const [resources, setResources] = useState<Record<string, APIProject[]>>({})
  // cancel token
  const [source, setSource] = useState<CancelTokenSource | null>(null)
  // query state
  const [state, dispatch] = useReducer(
    reducer as FilteredProjectsReducer,
    initialState
  )
  // eslint-disable-next-line consistent-return
  const getData = async (filters: string[]) => {
    dispatch({ type: 'LOAD' })
    if (source) {
      // Cancel the previous request before making a new request
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      source!.cancel()
    }
    // Create a new CancelToken
    setSource(axios.CancelToken.source())
    const url = constructUrl(filters)
    try {
      if (resources[url]) {
        // Return result if it exists
        dispatch({ type: 'SUCCESS', payload: resources[url] })
      } else {
        // Fetch and archive if not
        const result = await axios.get<APIProject[]>(url, {
          cancelToken: source?.token,
        })
        setResources(prev => ({ ...prev, [url]: result.data }))
        dispatch({ type: 'SUCCESS', payload: result.data })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
      } else {
        // Handle usual errors
        dispatch({ type: 'ERROR' })
      }
    }
    setSource(null)
  }

  return [state, getData] as const
}

export default useFilteredProjects
