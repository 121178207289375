import React, { Component, Children, useState, useEffect } from 'react'
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position'
import ScrollbarSize from 'react-scrollbar-size'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowsAltH } from '@fortawesome/pro-light-svg-icons'
import './style.scss'

import { useIntl } from 'react-intl'
import messages from './halved-images.messages'
import { FaArrowsAltH } from 'assets'

/* class MyComponent extends Component {
  scrollbarSizeLoad = (measurements) => {
    console.log('Scrollbars loaded', measurements);
  }
 
  scrollbarSizeChange = (measurements) => {
    console.log('Scrollbars changed', measurements);
  }
 
  render() {
    return (
      <div>
        <ScrollbarSize
          onLoad={this.scrollbarSizeLoad}
          onChange={this.scrollbarSizeChange}
        />
      </div>
    );
  }
} */

class HalvedImage extends React.Component {
  state = {
    scrollbarWidth: undefined,
  }

  scrollbarSizeLoad = measurements => {
    console.log('Scrollbars loaded', measurements)
    this.setState({ scrollbarWidth: measurements.scrollbarWidth })
  }

  scrollbarSizeChange = measurements => {
    console.log('Scrollbars changed', measurements)
    this.setState({ scrollbarWidth: measurements.scrollbarWidth })
  }

  render() {
    const { scrollbarWidth } = this.state
    const { beforeImg, afterImg } = this.props
    return (
      <ReactCursorPosition activationInteractionTouch={INTERACTIONS.TOUCH}>
        <PositionLabel
          scrollbarWidth={scrollbarWidth}
          beforeImg={beforeImg}
          afterImg={afterImg}
        />
        <ScrollbarSize
          onLoad={this.scrollbarSizeLoad}
          onChange={this.scrollbarSizeChange}
        />
      </ReactCursorPosition>
    )
  }
}

const color = ['#1E6194', '#304C61']
const defaultStyles = [
  { backgroundColor: color[0] },
  { backgroundColor: color[1] },
]
const PositionLabel = props => {
  const {
    detectedEnvironment: {} = {},
    elementDimensions: { width = 0, height = 0 } = {},
    position: { x = 0, y = 0 } = {},
    scrollbarWidth = 17,
    beforeImg,
    afterImg,
  } = props
  // const [itemStyles, setItemStyles] = useState(defaultStyles)
  // const onLoadImg = (index, url) => {
  //   setItemStyles(prev => {
  //     const newstyles = [...prev]
  //     newstyles[index] = { backgroundImage: `url(${url})` }
  //     return newstyles
  //   })
  // }
  // useEffect(() => {
  //   const newImg = new Image()
  //   newImg.src = beforeImg
  //   newImg.onload = () => {
  //     setItemStyles(prev => {
  //       const newstyles = [...prev]
  //       newstyles[index] = { backgroundImage: `url(${img.url})` }
  //       return newstyles
  //     })
  //   }
  // }, [homeImageData])
  const { formatMessage: f } = useIntl()
  return (
    <div className="architect imgs-wrapper halved container-fluid px-0">
      <div className="fixed-bg-wrappers">
        <div
          className="item-1 resizeable"
          style={{ width: '100%', height: '100%' }}
        >
          <div
            className="up-left img-item"
            style={{
              width: `calc(100vw - ${scrollbarWidth}px)`,
              backgroundImage: `url(${beforeImg})`,
            }}
          />
        </div>
        <div className="orientation-circle" style={{ left: x - 24 }}>
          <div className="arrows">
            {/* <FontAwesomeIcon icon={faArrowsAltH} /> */}
            <FaArrowsAltH />
          </div>
          {/* <span className="before">{f(messages.before)}</span>
          <span className="after">{f(messages.after)}</span> */}
        </div>
        <div className="item-3 resizeable" style={{ width: width - x }}>
          <div
            className="up-right img-item"
            style={{
              width: `calc(100vw - ${scrollbarWidth}px)`,
              backgroundImage: `url(${afterImg})`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default HalvedImage
