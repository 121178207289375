import React from 'react'

const SplashScreen = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center text-primary"
      style={{ height: '100vh' }}
    >
      <h1>Blok A3</h1>
    </div>
  )
}

export default SplashScreen
