import React, { useContext, useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router'
import { DataContext } from 'components/data-container/data-container'
import './projects.scss'
import { APIProject } from 'api/api-types'
import { MapObject } from 'api/types'
import SiteNavigationAddOnSm from 'components/navigation-modules/SiteNavigationAddOnSm'
import { routes } from 'assets/constants'
import ProjectGallery from 'views/projects/components/project-gallery'
// import placeholderProjects from 'assets/placeholder/projects'
import GoogleMapWithMarkers from 'components/google-map/google-map'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { Road } from 'components/google-map/components/road-map-marker/road-map-marker'
import { Building } from 'components/google-map/components/building-map-marker/building-map-marker'
import ProjectDetails from './components/project-details'
import { FaChevronDown } from 'assets'

const projToMapObj = (proj: APIProject): Building | Road => {
  return {
    location: {
      lat: proj.coordinatesList.latitude,
      lng: proj.coordinatesList.longitude,
    },
    title: proj.name || 'Marker',
    year: proj.year ? proj.year.toString() : '[year]',
    imgSrc: proj.url,
    permalink: proj.permaLink,
    // TODO determine property names names
    locationName: proj?.locationName,
    description: proj?.descriptionCro,
    euFund: proj?.euFonds,
    investor: proj?.investor,
  }
}
const isMapPositionDefined = (project: APIProject): boolean => {
  if (!project.coordinatesList) return false
  if (!project.coordinatesList.latitude) return false
  if (!project.coordinatesList.longitude) return false
  return true
}

const Projects: React.FC = () => {
  const match = useRouteMatch()
  const { projectsData } = useContext(DataContext)
  const [years, setYears] = useState<number[]>([])

  // get used years
  useEffect(() => {
    if (projectsData && projectsData.data) {
      const newYears: number[] = []
      projectsData.data
        .filter(p => p.projectTypeId === 1)
        .forEach(p => {
          if (!newYears.includes(p.year)) {
            newYears.push(p.year)
          }
        })
      setYears(newYears.sort().reverse())
    }
  }, [projectsData?.data])

  const isMapView = useRouteMatch(`${routes.projects}${routes.map}`)
  return (
    <section className="projects-section w-100 d-flex">
      <div className="row w-100 mx-0">
        <div
          className={`col-12 projects-container ${isMapView &&
            'd-flex flex-column'}`}
        >
          <SiteNavigationAddOnSm />
          <Switch>
            <Route path={`${match.path}/gallery`}>
              {years.map(year => {
                const projects = projectsData?.data
                  ?.filter(p => p.year === year)
                  .filter(p => p.projectTypeId === 1)
                return (
                  <div className="row list" key={year}>
                    <div className="aside-text">
                      {/* <FontAwesomeIcon icon={faChevronDown} /> */}
                      <FaChevronDown />
                      {` ${year}`}
                    </div>
                    <ProjectGallery
                      projects={
                        projects || [] /* .concat(placeholderProjects) */
                      }
                    />
                  </div>
                )
              })}
            </Route>
            <Route path={`${match.path}/map`}>
              <div className="row tab-content tab-2-content flex-grow-1 projects-map">
                <div className="col-12 px-0 d-flex">
                  <GoogleMapWithMarkers
                    buildings={projectsData?.data
                      ?.filter(
                        p => p.projectTypeId === 1 && isMapPositionDefined(p)
                      )
                      .map(projToMapObj)}
                    roads={projectsData?.data
                      ?.filter(
                        p => p.projectTypeId === 2 && isMapPositionDefined(p)
                      )
                      .map(projToMapObj)}
                  />
                </div>
              </div>
            </Route>
          </Switch>
          <Route path={`${match.path}/details/:projectName`}>
            <ProjectDetails />
          </Route>
        </div>
      </div>
    </section>
  )
}

export default Projects
