import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { routes } from 'assets/constants'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import funds from 'assets/constants/funds'
import messages from './footer.messages'
import { FaCircle } from 'assets'

function SiteFooter() {
  const { formatMessage: f } = useIntl()
  return (
    <footer className="row bg-diagonale border-top">
      <div className="col-12 col-xl d-flex flex-wrap pt-3 pt-xl-0">
        {funds.map(fund => (
          <a
            key={fund.img}
            href={fund.link}
            target="_blank"
            rel="noopener noreferrer"
            className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center"
            style={{ maxWidth: 200 }}
          >
            <img src={fund.img} alt="fund" className="w-100" />
          </a>
        ))}
      </div>
      <div className="col-12 col-xl-3 copy-right mx-auto flex-wrap">
        &#169;
        {f(messages.copyright)}
        <span className="bullet">
          {/* <FontAwesomeIcon icon={faCircle} /> */}
          <FaCircle />
        </span>
        <span className="privacy-policy">
          <Link to={routes.gdpr} target="_blank">
            {f(messages.ppLink)}
          </Link>
        </span>
      </div>
    </footer>
  )
}

export default SiteFooter
